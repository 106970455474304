





import Vue from 'vue'
import Component from 'vue-class-component'
import ListView from '@/components/list/ListView.vue'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component({
  components: {
    ListView,
  },
})
export default class List extends Vue {
  headers = []
  routes = {}
  topActions = []
  rowActions = []

  mounted() {
    this.headers = [
      {
        text: 'Licenseplate',
        value: 'licenseplate',
        filter: {
          focus: true,
        },
      },
      {
        text: 'Customer',
        value: 'customer.name',
      },
      {
        text: 'Driver',
        value: 'driver.name',
      },
      {
        text: 'Brand',
        value: 'brand.name',
      },
      {
        text: 'Model',
        value: 'model',
      },
      {
        text: 'Year',
        value: 'year',
      },
      {
        text: 'Tyre size',
        value: 'tyreSize',
      },
      {
        text: 'Reference no',
        value: 'referenceNumber',
      },
      {
        text: 'Actions',
        value: 'actions',
      },
    ]

    this.rowActions = [
      {
        id: 'show',
        route: (item) => {
          let customerId = item.customer?.id || 'U'
          return appendSitePrefix('/' + customerId + '/customer/show?car_id=' + item.id)
        },
      },
    ]

    this.topActions = [
      {
        id: 'new',
        route: () => {
          return appendSitePrefix('/N0/car/new')
        },
      },
    ]
  }
}
